import * as React from 'react'
import get from 'lodash/get'
import smoothscroll from 'smoothscroll-polyfill'
import Head from 'next/head'
import {useRouter} from 'next/router'
import dynamic from 'next/dynamic'
import {AppProvider} from '@components/AppProvider'
import type {NextPage} from 'next'
import type {AppProps} from 'next/app'

import {useGTMPageview, UTMContextProvider} from '@lib/gtm'
import {t} from '@lib/helpers'
import env from '@lib/env'

const Meta = dynamic(() => import('@components/Meta'))

import '../app.scss'


type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = (props: AppPropsWithLayout) => {
  const {Component, pageProps} = props
  const router = useRouter()
  const getLayout = Component.getLayout ?? ((page) => page)

  useGTMPageview()

  React.useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const removeQueryStringAndHash = (asPath = '') => {
    // eslint-disable-next-line no-useless-escape
    return asPath.replace(/(\?|\#).*$/, '').replace(/\/$/, '')
  }

  return (
    <React.Fragment>
      {(() => {
        const _host = env.APP_URL
        const url = `${_host}${get(router, 'asPath')}`
        return (
          <React.Fragment>
            <Meta
              title={t('layout.title')}
              description={t('layout.description')}
              image='banner.png'
            />

            <Head>
              <meta charSet='utf-8' />
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
              />
              <meta name='robots' content='index,follow' />
              <meta name='googlebot' content='index,follow' />

              <meta
                name='og:url'
                property='og:url'
                content={removeQueryStringAndHash(url)}
              />
              <meta name='og:type' property='og:type' content='website' />

              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:site' content={url} />
              {/* <meta name="twitter:creator" content="@" /> */}

              <link rel='preconnect' href='https://analytics.google.com' />
              <link rel='preconnect' href='https://stats.g.doubleclick.net' />

              <link rel='canonical' href={removeQueryStringAndHash(url)} />
              <script type='application/ld+json'>
                {`{
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Data Wow",
                    "alternateName": "Data Wow",
                    "url": "https://datawow.co.th/",
                    "logo": "https://datawow.co.th/images/logo-datawow.svg",
                    "description":"Data Wow is a startup AI company that provides customized AI services and solutions.",
                    "address":{
                       "@type":"PostalAddress",
                       "streetAddress":"1778 Summer Hub Offices, 6th Fl., Sukhumvit Rd., Phra Khanong, Klong Toey",
                       "addressLocality":"Bangkok",
                       "postalCode":"10110",
                       "addressCountry":"TH"
                    }
                  }`}
              </script>
              <link
                rel='apple-touch-icon'
                sizes='180x180'
                href='/apple-touch-icon.png'
              />
              <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href='/favicon-32x32.png'
              />
              <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href='/favicon-16x16.png'
              />
              <link rel='manifest' href='/site.webmanifest' />
              <link
                rel='mask-icon'
                href='/safari-pinned-tab.svg'
                color='#5bbad5'
              />
              <meta name='msapplication-TileColor' content='#ffffff' />
              <meta name='theme-color' content='#ffffff' />
            </Head>
          </React.Fragment>
        )
      })()}

      <AppProvider>
        <UTMContextProvider>
          <main>{getLayout(<Component {...pageProps} />)}</main>
        </UTMContextProvider>
      </AppProvider>
    </React.Fragment>
  )
}

export default MyApp
